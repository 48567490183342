<template>
    <div>
        <p class="title white--text mb-1">Check Your Phone!</p>
                                                        
            <p class="subtitle-1 lh-13 white--text mb-0">We sent a code to {{ user.phone }} to verify your number is correct.</p>

            <v-divider dark class="mt-3 mb-4 mb-md-5" />

            <v-alert dense type="error" class="body-2 mb-6 text-left font-weight-medium" v-if="errors">
                {{ errors }}
            </v-alert>

            <template v-if="!update">

                <v-card dark color="transparent">
                    <v-card-text class="pa-0">
                        <p class="subtitle-1 lh-13 white--text mb-3">Enter you code:</p>
                        <CodeInput :size="4" @updated="code = $event; verify();"  />
                    </v-card-text>
                </v-card>

                <v-divider class="my-5" dark />

                <p class="mb-3 text-center">
                    <v-btn color="primary" dark @click.native="verify()" :loading="loading" :disabled="code.length!=4" class="px-7">Verify Phone Number</v-btn>
                </p>
                <p class="mb-0 text-center">
                    <v-btn small text color="primary" :loading="resending" @click="resend()">Resend Code</v-btn>
                </p>
                <p class="mb-0 text-center">
                    <v-btn small text color="primary" @click.native="update=true">Update Phone Number</v-btn>
                </p>
            </template>
            <template v-else>

                <v-text-field
                    :key="updateKey"
                    v-model="phone"
                    :autofocus="!$vuetify.breakpoint.smAndDown" 
                    outlined
                    label="Your Phone Number"
                    type="tel"
                    prepend-inner-icon="fa fa-phone"
                    class="hide-empty-msgs mx-auto mb-3"
                    prefix="+1"
                    :success="phone.length == 14"
                    placeholder="Ex: (555) 555 - 5555"
                    style="max-width: 300px;"
                    v-mask="'(###) ###-####'"
                    hint="Must be able to receive text messages."
                ></v-text-field>

                <p class="mb-3 text-center">
                    <v-btn color="primary" @click.native="updatePhone()" :loading="updating" :disabled="phone.length!=14" class="px-7">Update Phone Number</v-btn>
                </p>
                <p class="mb-0 text-center">
                    <v-btn small text color="primary" :loading="resending" @click.native="update=false">Cancel</v-btn>
                </p>

            </template>

    </div>
</template>
<script>

import { mapState } from 'vuex'

export default {

    name: 'StartVerifyPhone',
    props: {
    },
    components: {
        CodeInput: () => import('@/components/common/CodeInput'),
    },
    computed: {
        ...mapState(['user'])        
    },
    data: () => ({
        code: '',
        errors: null,
        loading: false,
        phone: 'sdf',
        resending: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 3 || 'Must be at least 4 numbers.',
        },
        showResend: false,
        update: false,
        updating: false,
        updateKey: 0,
    }),
    methods: {
        initData(){
            this.update = false;
            let cp = JSON.parse(JSON.stringify(this.user));
            this.phone = cp.phone;
        },
        resend(){
            this.resending = true;
            let request = {
                endpoint: '/sendverificationcode', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your verification code has been sent!'
                    });
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.resending = false;
                this.showResend = false;
            });
        },
        updatePhone(){
            this.updating = true;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    phone: this.phone,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your phone number has been updated!'
                    });
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.updating = false;
                this.update = false;
            });
        },
        verify(){
            this.loading = true;
            let request = {
                endpoint: '/verifyphone', 
                method: 'post',
                data: {
                    code: this.code
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your phone has been verified!'
                    });
                    this.$emit('verified');
                }else{
                    this.errors = "Invalid code. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.loading = false;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
    },
    mounted(){
        this.initData();
    },
    watch: {
        update(){
            ++this.updateKey;
        }
    }
}
</script>
